import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import React, { FormEvent, useState } from 'react';

const FigmaEmbed: React.FC = React.memo(() => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [isEmailSubmitted, setIsEmailSubmitted] = useState<boolean>(true);

  const handleIframeLoad = () => {
    setTimeout(() => {
      setIsEmailSubmitted(false);
      setIsLoading(false);
    }, 8000);
  };

  const handleEmailSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (email) {
      setIsEmailSubmitted(true);
    }
  };

  return (
    <div>
      <div className="pb-10">
        <div className="text-[24px] sm:text-4xl leading-[2.5rem] lg:leading-[3.5rem] text-[#101010] text-center">
          Experience Squad in action
        </div>
        <div className="text-[#7A7A7A] text-xl text-center mt-4">
          Take a live tour to see how our productivity tools can transform your
          team’s workflow.
        </div>
      </div>
      <div className="w-full h-[100vh] m-0 p-0 relative">
        {/* Loading Spinner */}
        {isLoading && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white">
            <Spinner
              size={SpinnerSize.large}
              label="Loading Figma Prototype..."
            />
          </div>
        )}

        {/* Figma Embed with Reduced Blur */}
        <iframe
          className={`border-0 w-full h-[100vh] transition-all duration-300 ${!isEmailSubmitted ? 'blur-sm' : ''}`}
          src="https://embed.figma.com/proto/fSkh9C5PPirehhhPiUYlaL/Main?page-id=0%3A1&node-id=1348-78680&node-type=frame&viewport=-8553%2C-20295%2C0.62&scaling=scale-down&content-scaling=fixed&starting-point-node-id=1348%3A78680&share=1&embed-host=share&hide-ui=1"
          allowFullScreen
          onLoad={handleIframeLoad}
          title="Experience Squad in action"
        ></iframe>

        {/* Email Input Overlay */}
        {!isEmailSubmitted && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-50">
            <form
              onSubmit={handleEmailSubmit}
              className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center"
            >
              <label htmlFor="email" className="mb-2 text-lg font-medium">
                Enter your email to explore the prototype
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="border p-2 rounded-md w-64 mb-4"
                placeholder="Enter your email"
              />
              <DefaultButton
                type="submit"
                className="border border-[#185D74] bg-[#185D74] hover:bg-[#2b92b4] text-white hover:text-white px-3 py-3 ease-in-out duration-300"
              >
                Take Tour
              </DefaultButton>
            </form>
          </div>
        )}
      </div>
    </div>
  );
});

export default FigmaEmbed;
