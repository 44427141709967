import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect, useRef } from 'react';
import { Footer } from '../components/CommonComponent/Footer';
import { Header } from '../components/CommonComponent/Header';
import AiAssistance from '../components/Feature/AiAssistance';
import CxoInsight from '../components/Feature/CxoInsight';
import EmployeeWellness from '../components/Feature/EmployeeWellness';
import FeatureHeader from '../components/Feature/FeatureHeader';
import FinancePlanner from '../components/Feature/FinancePlanner';
import GoalsAndPerformance from '../components/Feature/GoalsAndPerformance';
import MagicPlanner from '../components/Feature/MagicPlanner';
import ProductivityDashboard from '../components/Feature/ProductivityDashboard';
import ProductivityInsights from '../components/Feature/ProductivityInsights';
import ProjectManagements from '../components/Feature/ProjectManagement';
import ResourceManagement from '../components/Feature/ResourceManagement';
import { Starter } from '../components/Feature/Starter';
import TimeSheetAndLeave from '../components/Feature/TimeSheetAndLeave';
import UpSkillAndAssessment from '../components/Feature/UpSkillAndAssessment';
import BoostYourProductivity from '../components/Home/BoostYourProductivity';

const sections = [
  {
    title: 'Productivity Insights',
    component: (
      <>
        {' '}
        <ProductivityInsights /> <CxoInsight />{' '}
      </>
    ),
  },
  {
    title: 'AI Assistance',
    component: (
      <>
        {' '}
        <AiAssistance />
        <MagicPlanner />
      </>
    ),
  },
  { title: 'Project Management', component: <ProjectManagements /> },
  { title: 'Upskill & Assessment', component: <UpSkillAndAssessment /> },
  { title: 'Goals & Performance', component: <GoalsAndPerformance /> },
  { title: 'Resource Management', component: <ResourceManagement /> },
  { title: 'Finance Planner', component: <FinancePlanner /> },
  { title: 'Productivity Dashboard', component: <ProductivityDashboard /> },
  { title: 'Timesheet & Leave', component: <TimeSheetAndLeave /> },
  { title: 'Employee Wellness', component: <EmployeeWellness /> },
  // { title: 'Boost Your Productivity', component: <BoostYourProductivity /> },
];

const Feature = () => {
  const [, setLayoutState] = useLayoutState();

  const sectionRefs = useRef<(HTMLDivElement | null)[]>(
    Array(sections.length).fill(null),
  );

  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  const scrollToSection = (index: number) => {
    if (sectionRefs.current[index]) {
      sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative">
      <Header />
      <Starter />
      <FeatureHeader
        scrollToSection={scrollToSection}
        sectionTitles={sections.map((section) => section.title)}
      />
      {sections.map((section, index) => (
        <section
          ref={(el: HTMLDivElement | null) => (sectionRefs.current[index] = el)}
          key={index}
          className={`py-[166px] ${index % 2 === 0 ? 'bg-white' : 'bg-[#1A1A1A]'}`}
        >
          {section.component}
        </section>
      ))}
      <section>
        <BoostYourProductivity />
      </section>
      <Footer />
    </div>
  );
};

export default Feature;
