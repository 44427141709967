import { DefaultButton, MessageBarType, Spinner } from '@fluentui/react';
import {
  Form,
  PhoneInputField,
  STextFormFieldStyle,
  STextFormFieldStyleMobileView,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import LinkSvg from '../Home/assets/LinkSvg';
import { useContactUsMutation } from '../Home/home.generated';
import { tryUsSchema } from '../Home/tryUs.schema';

export const TryUs = () => {
  const [, contactUs] = useContactUsMutation();
  const { notification } = useNotification();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;
  const tryUsForm = useForm({
    mode: 'all',
    resolver: tryUsSchema,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '91',
    },
  });
  const { handleSubmit } = tryUsForm;
  const onSubmit = async (data: any) => {
    setLoader(true);
    try {
      const res = await contactUs({
        object: {
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone_no,
        },
      });
      if (res.data) {
        notification({
          message:
            'Thank you for contacting us. We will get back to you shortly.',
          type: MessageBarType.success,
        });
        setLoader(false);
        navigate('/thank-you');
        tryUsForm.reset();
      } else {
        notification({
          message: res.error?.message + '',
          type: MessageBarType.error,
        });
      }
    } catch (e) {
      notification({
        message: e + '',
        type: MessageBarType.error,
      });
    }
  };
  return (
    <div className="p-20 flex justify-center items-center">
      <Form
        className="flex flex-col w-full sm:w-full gap-5 items-center"
        formHook={tryUsForm}
        onSubmit={() => handleSubmit(onSubmit)}
      >
        <div className="text-[#000000] text-4xl font-semibold leading-[2.5rem] sm:leading-[3.5rem] flex items-center flex-wrap sm:flex-wrap gap-4">
          Reach Out to Learn More About Worx Squad
        </div>

        <div className="flex flex-col sm:flex-row gap-5 mt-[44px]">
          <label>
            First Name
            <TextField
              formHook={tryUsForm}
              name="first_name"
              label=""
              styles={
                isMobile ? STextFormFieldStyleMobileView : STextFormFieldStyle
              }
              placeholder="Enter first name"
              className="w-full"
            />
          </label>
          <label>
            Last Name
            <TextField
              formHook={tryUsForm}
              name="last_name"
              styles={
                isMobile ? STextFormFieldStyleMobileView : STextFormFieldStyle
              }
              placeholder="Enter last name"
              className="w-full"
            />
          </label>
        </div>

        <div className="flex flex-col sm:flex-row gap-5">
          <label className="mt-[4px]">
            Email
            <TextField
              formHook={tryUsForm}
              name="email"
              styles={
                isMobile ? STextFormFieldStyleMobileView : STextFormFieldStyle
              }
              placeholder="Enter company email address"
              className="w-full rounded-md"
              style={{ borderRadius: '8px' }}
            />
          </label>
          <div>
            <label>Phone Number</label>
            <PhoneInputField
              country={moment.tz.guess()}
              placeholder="Enter phone number"
              formHook={tryUsForm}
              name="phone_no"
              label=""
              inputStyle={{
                width: isMobile ? '100%' : '322px',
                height: '52px',
                borderRadius: '8px',
              }}
            />
          </div>
        </div>
        <div className="w-auto mt-[56px]">
          <DefaultButton
            onClick={handleSubmit(onSubmit)}
            className="bg-[#FF9900] w-full sm:w-auto border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
          >
            {loader ? (
              <Spinner />
            ) : (
              <span className="flex justify-center items-center gap-4">
                <span className="text-[1rem]">Get Started</span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            )}
          </DefaultButton>
        </div>
      </Form>
    </div>
  );
};
