import { experienceList } from './Utils';

const Experience = () => {
  return (
    <div className="p-20 ">
      <div className="text-[24px] sm:text-4xl leading-[2.5rem] lg:leading-[3.5rem] text-[#101010] text-center">
        What improves experience
      </div>
      <div className="text-[#7A7A7A] text-xl text-center mt-4">
        Discover the features that elevate user satisfaction and engagement
      </div>
      <div className="flex flex-wrap justify-between py-8 gap-4">
        {experienceList.map(({ Icon, title, description }, index) => (
          <div
            key={index}
            className="flex-grow w-full sm:w-1/2 lg:w-1/3 xl:w-[25%] p-3 sm:p-6 bg-white rounded-xl shadow-lg hover:bg-gradient-to-b hover:from-[rgba(43,43,43,0.9)] hover:to-[rgba(24,93,116,0.9)] hover:text-white hover:transition-all hover:duration-500 hover:scale-110"
          >
            <div className="flex justify-center">
              <Icon />
            </div>
            <div className="text-center py-2">
              <div className="text-[24px] text-bold font-semibold py-[12px]">
                {title}
              </div>
              <div className="text-[1rem]">{description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Experience;
