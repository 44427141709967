import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import LinkSvg from '../Home/assets/LinkSvg';
import JoinAsPartnerModal from './JoinAsPartnerModal';

const JoinAsPartner = () => {
  const [isModalOpenOne, { setTrue: showModalOne, setFalse: hideModalOne }] =
    useBoolean(false);
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center p-20">
        <div className="flex flex-col items-center gap-12">
          <div className="text-[24px] sm:text-4xl leading-[2.5rem] lg:leading-[3.5rem] text-[#101010] text-center">
            Join as a Consulting Partner
          </div>
          <div className="text-[#7A7A7A] text-xl text-center mt-4">
            Join our network of experts helping organizations unlock their full
            productivity potential. As a consulting partner, you’ll gain
            exclusive access to resources, training, and a dedicated support
            team to drive real results for your clients with Worxwide Squad."
          </div>
          <div className="w-auto text-center flex justify-center items-center">
            <DefaultButton
              onClick={showModalOne}
              className="w-full border border-[#FF9900] bg-[#FF9900] hover:bg-[#e19a30] hover:text-white px-6 py-6 ease-in-out duration-300"
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem] text-white font-semibold">
                  Join now
                </span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
          </div>
          <JoinAsPartnerModal
            showModalOne={showModalOne}
            isModalOpenOne={isModalOpenOne}
            hideModalOne={hideModalOne}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinAsPartner;
