import { IconButton, Text } from '@fluentui/react';
import { useState } from 'react';
import { item } from './Utils';

export const FAQ = () => {
  const items = item.map((i, key) => {
    return {
      key: key,
      content: i.content,
      title: i.title,
    };
  });

  return (
    <div className="bg-opacity-20 px-[20px] sm:px-[40px] flex flex-col justify-center items-center py-[60px]">
      <div className="text-[24px] sm:text-4xl leading-[2.5rem] lg:leading-[3.5rem] text-[#101010] text-center">
        Frequently Asked Questions
      </div>
      <div className="text-[#7A7A7A] text-xl text-center mt-4">
        Find the answers to common questions and learn how Worx Squad can
        transform your business operations
      </div>
      <div className="border border-gray-300 p-5 mt-10 w-[70%] ">
        {items.map((item, index) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [disabled, setDisabled] = useState(false);
          if (index === item.key) {
            return (
              <div className="pl-2 lg:pl-0" key={index}>
                <div
                  className="text-left mr-1 flex justify-between gap-10 sm:gap-96 cursor-pointer"
                  onClick={() => {
                    setDisabled(!disabled);
                  }}
                >
                  <Text className="text-lg font-semibold text-black">
                    {item.title}
                  </Text>
                  <div className="border-[2px] border-black rounded-full pt-[1.75px] px-[2.5px] text-center h-[26px] w-[26.5px]">
                    <IconButton
                      className="text-black hover:text-black hover:bg-transparent p-0"
                      iconProps={
                        disabled
                          ? { iconName: 'CalculatorSubtract' }
                          : { iconName: 'Add' }
                      }
                      styles={{
                        root: {
                          width: '18px',
                          height: '18px',
                        },
                        icon: {
                          fontSize: '12px',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </div>
                </div>
                <div
                  className="mr-5 sm:mr-0 pr-[3rem]"
                  style={{ textAlign: 'left' }}
                >
                  <Text
                    className="font-medium text-lg text-gray-500"
                    style={!disabled ? { display: 'none' } : {}}
                  >
                    {item.content}
                  </Text>
                </div>

                {index !== 11 && (
                  <div className="my-4 h-[0.5px] bg-black opacity-20"></div>
                )}
              </div>
            );
          } else {
            return '';
          }
        })}
      </div>
    </div>
  );
};
