import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import { Footer } from '../components/CommonComponent/Footer';
import { Header } from '../components/CommonComponent/Header';
import JoinAsPartner from '../components/CommonComponent/JoinAsPartner';
import { TryUs } from '../components/CommonComponent/TryUs';
import BoostYourProductivity from '../components/Home/BoostYourProductivity';
import CustomersFeedback from '../components/Home/CustomersFeedback';
import Experience from '../components/Home/Experience';
import { FAQ } from '../components/Home/FAQ';
import FigmaEmbed from '../components/Home/FigmaEmbed';
import Productivity from '../components/Home/Productivity';
import { ProductivityNExperiencePlatform } from '../components/Home/ProductivityNExperiencePlatform';
import { Starter } from '../components/Home/Starter';
import LifeCycle from '../components/Home/assets/LifeCycle.png';

const Home = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  return (
    <div className="relative">
      <Header />
      <Starter />
      <Productivity />
      <div className="p-20">
        <img src={LifeCycle} alt="LifeCycle" />
      </div>

      <FigmaEmbed />
      <Experience />
      <ProductivityNExperiencePlatform />
      <JoinAsPartner />
      <CustomersFeedback />
      <FAQ />
      <TryUs />
      <BoostYourProductivity />
      <Footer />
    </div>
  );
};

export default Home;
