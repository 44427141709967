import { Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Input.scss';
interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
export interface PhoneInputFieldProps extends PhoneInputProps {
  formHook: UseFormReturn<any>;
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
}

export const PhoneInputField: FC<PhoneInputFieldProps> = ({
  formHook,
  name,
  label,
  placeholder,
  required = false,
  ...props
}) => {
  const { control } = formHook;
  const labelId = useId('label');
  const errorId = useId('errorMessage');
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <div className="mt-[5px]">
          <Text
            styles={{
              root: {
                fontWeight: 500,
                borderRadius: '8px',
              },
            }}
            id={labelId}
          >
            {`${label} `}
            {required && <span className="text-red-500">*</span>}
          </Text>
          <PhoneInput
            containerStyle={{
              marginTop: 5,
            }}
            onChange={(value, country) => {
              onChange(value);
            }}
            placeholder={placeholder || ''}
            onBlur={onBlur}
            value={value}
            {...props}
          />
          <Text
            id={errorId}
            variant="small"
            styles={{
              root: {
                color: '#a4262c',
              },
            }}
          >
            {error && error?.message}
          </Text>
        </div>
      )}
    />
  );
};
