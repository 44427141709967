import AI from './assets/AI.png';
import FinancialManagement from './assets/FinancialManagement.png';
import KudosNRewards from './assets/KudosNRewards';
import LiveOffice from './assets/LiveOffice';
import PerformanceReview from './assets/PerformanceReview.png';
import ProjectManagent from './assets/ProjectManagent.png';
import ResourceManagement from './assets/ResourceManagement.png';
import SocialBonding from './assets/SocialBonding';
import TeamActivity from './assets/TeamActivity';
import Upskilling from './assets/Upskilling.png';
import WallOfFame from './assets/WallOfFame';
import WallOfMoments from './assets/WallOfMoments';
export const CustomersFeedbackCardDetails = [
  {
    // customerImage: (
    //   <img
    //     src="../../../assets/Ellipse.png"
    //     alt="profileImage"
    //     height={'60px'}
    //     width={'60px'}
    //   />
    // ),
    name: 'Sarah Mitchell',
    role: 'Operations Manager at ThriveCorp',
    companyLogo: <img src={'../../../assets/Cap.png'} alt="logo" />,
    feedback:
      'Worx Squad has streamlined our workflow like never before. Our team’s productivity has increased by 30% in just a few months. The intuitive interface and powerful features make collaboration easy, even across remote teams!',
  },
  {
    // customerImage: (
    //   <img
    //     src="../../../assets/Ellipse.png"
    //     alt="profileImage"
    //     height={'60px'}
    //     width={'60px'}
    //   />
    // ),
    name: 'John Ramirez',
    role: 'Project Lead at InnovateX Solutions',
    companyLogo: <img src={'../../../assets/Product.png'} alt="logo" />,
    feedback:
      'The insights provided by Worx Squad have transformed how we manage projects. It’s now simple to track our progress and allocate resources efficiently. Our meetings are shorter, and our team is more focused on what really matters.',
  },
  {
    // customerImage: (
    //   <img
    //     src="../../../assets/Ellipse.png"
    //     alt="profileImage"
    //     height={'60px'}
    //     width={'60px'}
    //   />
    // ),
    name: 'Priya Singh',
    role: 'Head of Product Development at NextGen Labs',
    companyLogo: <img src={'../../../assets/G.png'} alt="logo" />,
    feedback:
      'As a growing team, we needed a platform that could keep up with our fast pace. Worx Squad has not only kept up but helped us work smarter. The task management and collaboration features are exactly what we needed',
  },
];

export const BookADemo = () => {
  const isMobile = window.innerWidth <= 768;
  let popupWidth = 0;
  let popupHeight = 0;
  let left = 0;
  let top = 0;

  if (isMobile) {
    popupWidth = 300;
    popupHeight = 500;
    left = (window.innerWidth - popupWidth) / 2;
    top = (window.innerHeight - popupHeight) / 2;
  } else {
    popupWidth = 1000;
    popupHeight = 800;
    left = window.screenX + (window.outerWidth - popupWidth) / 2;
    top = window.screenY + (window.outerHeight - popupHeight) / 2;
  }

  const popup = window.open(
    'https://calendly.com/worx-squad/demo',
    '_blank',
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`,
  );

  if (popup) {
    popup.focus();
  }
};

export const item = [
  {
    title: 'Can I un-subscribe the plan in-between?',
    content:
      ' No, if you have purchased a plan for a month or for a year, you will be charged the whole amount in advance and which is non-refundable. However, you have 60 days free trial to test the application.',
  },
  {
    title: 'Do I need to enter payment details for free trial?',
    content:
      ' Not currently, it is free to use without payment details in this version',
  },
  {
    title: 'Do you accept international payment options?',
    content:
      'Yes, our Stripe and Paypal integration allows international payments',
  },
  {
    title:
      'Do we have the option to disable video preferences for live office?',
    content: 'Yes, but at organization level only.',
  },
  {
    title: 'Why shall be buy Worx Squad when we are on teams/slack?',
    content:
      'Worx Squad offers in-office experience, the way you go to office physically and operate. It’s deep personalization experience sets it apart from any other office applications. Besides, Worx Squad is not limited to audio/video communication, it integrates a lot of day to day office operations within it.',
  },
  {
    title:
      'Can employees directly login and starting using the application from the website?',
    content:
      'No, employees will be invited by the Org Admin to have a username and password to login to the application. However, application can be downloaded from the website.',
  },
  {
    title: 'Do you offer the same application in web experience?',
    content:
      'Not currently, it is available in desktop app experience only for Windows and MAC.',
  },
  {
    title: 'Do you offer the same application as mobile version?',
    content:
      'Not currently, it is available in desktop app experience only for Windows and MAC.',
  },
  {
    title: 'How many users I can invite or add in my organization?',
    content:
      'Upto 1000, however, while updating one time organization settings you need to choose the office capacity or number of seats accordingly to display such capacity in the live office.',
  },
  {
    title:
      'Can cross-country offices on-board on same environment of the same organization?',
    content:
      'Yes, if company has offices globally, either they can invite multiple people across the world on same environment by the org admin, or they can setup it as a new organization in the local country with a different name and domain.',
  },
  {
    title: ' Can we invite external users in the application?',
    content:
      'Limited, you can invite external users only for audio/video calls or scheduled meetings. External domain users cannot access any other page or functionality of the application.',
  },
  {
    title: 'How does the application address privacy concerns?',
    content:
      'The application is designed in compliance with GDPR and other local compliances. Our Amazon S3 server is located in XXX, and secures with XYZ protection. Customer’s data privacy and security safeguards are important for Worx Squad. Worx Squad complies with GDPR and other data protection mechanisms worldwide. We have provided comprehensive information on our security and data protection measures in the terms of service section. ',
  },
];

export const productivityItems = [
  {
    title: 'AI Predictions',
    content:
      'Analyse and get insights on billable vs non billable hours charged on weekly timesheets.',
    icon: <img src={AI} alt="AI" style={{ width: '-webkit-fill-available' }} />,
  },
  {
    title: 'Project Management',
    content:
      'Track tasks status (time to complete tasks) & deviations in project goals timelines.',
    icon: (
      <img
        src={ProjectManagent}
        alt="ProjectManagent"
        style={{ width: '-webkit-fill-available' }}
      />
    ),
  },
  {
    title: 'Resource Management',
    content:
      'Analyse weekly bandwidth of employees (basis current tasks assigned) and assign work accordingly.',
    icon: (
      <img
        src={ResourceManagement}
        alt="ResourceManagement"
        style={{ width: '-webkit-fill-available' }}
      />
    ),
  },
  {
    title: 'Financial management',
    content:
      'Analyse time spent grid behaviours like active time, burnout time, meetings time, tasks time, social time.',
    icon: (
      <img
        src={FinancialManagement}
        alt="FinancialManagement"
        style={{ width: '-webkit-fill-available' }}
      />
    ),
  },
  {
    title: 'Performance Review',
    content:
      'Monitor real-time progress of quarterly or annual goals and KPIs on dashboards.',
    icon: (
      <img
        src={PerformanceReview}
        alt="PerformanceReview"
        style={{ width: '-webkit-fill-available' }}
      />
    ),
  },
  {
    title: 'Upskilling',
    content:
      'Analyse skill deviations for a role through assessments and suggest trainings to upskill. Remember, less skills equals less productivity.',
    icon: (
      <img
        src={Upskilling}
        alt="Upskilling"
        style={{ width: '-webkit-fill-available' }}
      />
    ),
  },
];

export const experienceList = [
  {
    Icon: LiveOffice,
    title: 'Live office Collaboration',
    description:
      'Collaborate with in-office experience (tap & talk, live audio-video a tap away.)',
  },
  {
    Icon: KudosNRewards,
    title: 'Kudos & Rewards',
    description:
      'Motivate employees by point-based redeemable rewards & kudos as recognitions.',
  },
  {
    Icon: WallOfMoments,
    title: 'Wall of Moments',
    description:
      'Share formal or informal updates with a specific team or with the whole organisation on the wall to comment/like.',
  },
  {
    Icon: WallOfFame,
    title: 'Wall of Fame',
    description:
      'Wall displaying photos of achievers, work anniversaries, and extra milers.',
  },
  {
    Icon: SocialBonding,
    title: 'Social Bondings',
    description:
      'Auto reminders to wish for birthdays, wedding anniversaries, and new joiners.',
  },
  {
    Icon: TeamActivity,
    title: 'Team activities',
    description: 'Felicitation of team games, and celebrations.',
  },
];
